const reviews = [
  {
    author: 'Olli Böök',
    text: 'Hole19 Premium helps you to know your own weaknesses and strengths. The real level of your game.',
  },
  {
    author: 'Edoardo Noseda',
    text: 'After testing other apps, I decided to continue with Hole19. After one year, I upgraded to Premium to use all of the features. It is stable and works well with Apple Watch.',
  },
  {
    author: 'Mitch Adler',
    text: 'Hole19 is simply terrific! Easy, quick, accurate… runs all by itself! All I have to do is grab the right club and hit it straight!',
  },
  {
    author: 'Veruski Kings',
    text: 'This is the best golf app I\'ve seen. Super accurate distances! It helps me to improve my game. Thanks Hole19!',
  },
  {
    author: 'Peter Romão',
    text: 'Hole19 Premium helps me get track of my club distances, more accurately, during my golf rounds.',
  },
  {
    author: 'Robin Hamilton',
    text: 'Thanks to Hole19 Premium my golf game is headed in the right direction. Trendline graphs allow me to understand recent development and expected future progress. Provides feedback and motivation in equal measure.',
  },
  {
    author: 'Rafaela Szabo',
    text: 'When someone asks me which is the most important tool to take on the course, I don’t think twice, Hole19. Every version is always better than the last one. Great job Hole19 team.”.',
  },
];

const speed = window.innerWidth > 900 ? 50 : 40;

document.addEventListener('DOMContentLoaded', function () {
  (function (selector, reviewsArray, sliderSpeed) {
    const container = document.querySelector(selector);

    const buildUI = function () {
      reviewsArray.forEach((review) => {
        const UIReviewCard = document.createElement('li');
        const UIReviewContent = document.createElement('div');
        const UIReviewText = document.createElement('span');
        const UIReviewAuthor = document.createElement('span');

        UIReviewAuthor.innerText = review.author;
        UIReviewText.innerText = review.text;

        UIReviewCard.classList.add('review-card');
        UIReviewContent.classList.add('review-content');
        UIReviewText.classList.add('review-text');
        UIReviewAuthor.classList.add('review-author');

        UIReviewContent.appendChild(UIReviewAuthor);
        UIReviewContent.appendChild(UIReviewText);
        UIReviewCard.appendChild(UIReviewContent);

        container.appendChild(UIReviewCard);
      });
    };

    let containerPosition = 0;

    const moveContainer = function () {
      container.style.transform = `translate(${containerPosition}px)`;
      containerPosition -= 0.5;

      const firstElement = document.querySelectorAll(`${selector} li`)[0];
      const firstElementWidth = firstElement.offsetWidth + parseInt(getComputedStyle(firstElement).marginLeft, 10);

      // When the container has slides all the way through, reset its position
      if (Math.abs(containerPosition) >= (reviewsArray.length * firstElementWidth)) {
        containerPosition = 0;
      }
    };

    // Build the UI 2x
    buildUI();
    buildUI();

    // Moves the container
    setInterval(moveContainer, sliderSpeed);
  }('.premium-reviews-slider .reviews-container', reviews, speed));
});
